import React from 'react';
import './App.css';
import StormEvents from './components/StormEvents/StormEvents';
import ClientManager from './components/ClientManager/ClientManager';

function App() {
  return (
    <div className="App">
      <main>
        <StormEvents />
        <ClientManager />
      </main>
    </div>
  );
}

export default App;