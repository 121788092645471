import { openCageKey } from '../constants/apiConstants';

function useGeocoding() {
  const getCoordinates = async (address) => {
    try {
      const geoResponse = await fetch(
        `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
          address
        )}&key=${openCageKey}`
      );
      const geoData = await geoResponse.json();
      
      if (geoData.results.length === 0) {
        throw new Error('No location found. Please try another address.');
      }

      const { lat, lng } = geoData.results[0].geometry;
      return { lat, lng };
    } catch (error) {
      throw new Error('Error getting coordinates: ' + error.message);
    }
  };

  const getAddressSuggestions = async (input) => {
    try {
      const response = await fetch(
        `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(input)}&key=${openCageKey}&limit=5&autocomplete=1`
      );
      const data = await response.json();
      return data.results.map(result => result.formatted);
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
      return [];
    }
  };

  return { getCoordinates, getAddressSuggestions };
}

export default useGeocoding;