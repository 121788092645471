import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';
import ExtraMarkers from 'leaflet-extra-markers';
import { calculateOpacity } from '../../utils/dateUtils';
import { markerIcon, markerIconRetina, markerShadow, searchIcon } from '../../constants/mapConstants';

// Fix Leaflet's default icon URLs
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIconRetina,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

function EventsMap({ coordinates, events, dataSource, radius }) {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const searchMarkerRef = useRef(null);

  useEffect(() => {
    if (!mapInstanceRef.current && mapRef.current && coordinates) {
      mapInstanceRef.current = L.map(mapRef.current).setView([coordinates.lat, coordinates.lng], getZoomLevel(radius));
  
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
      }).addTo(mapInstanceRef.current);
    }

    if (mapInstanceRef.current && coordinates) {
      mapInstanceRef.current.setView([coordinates.lat, coordinates.lng], getZoomLevel(radius));
  
      if (searchMarkerRef.current) {
        searchMarkerRef.current.setLatLng([coordinates.lat, coordinates.lng]);
      } else {
        searchMarkerRef.current = L.marker([coordinates.lat, coordinates.lng], { icon: searchIcon })
          .addTo(mapInstanceRef.current)
          .bindPopup('Search Address');
      }
    }
  }, [coordinates, radius]);

  useEffect(() => {
    if (mapInstanceRef.current && coordinates && events.length > 0) {
      // Remove all event markers, but preserve the search marker
      mapInstanceRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker && layer !== searchMarkerRef.current) {
          mapInstanceRef.current.removeLayer(layer);
        }
      });
  
      // Add new markers for events
      events.forEach(event => {
        const lat = dataSource === 'NOAA' ? event.BEGIN_LAT : event.Lat;
        const lon = dataSource === 'NOAA' ? event.BEGIN_LON : event.Lon;
        const magnitude = dataSource === 'NOAA' ? (event.MAGNITUDE || 'N/A') : event.SizeInInches;
        const magnitudeType = dataSource === 'NOAA' ? (event.MAGNITUDE_TYPE || '') : 'inches';
        const opacity = dataSource === 'NOAA' ? calculateOpacity(event.BEGIN_DATE_TIME) : 1;
  
        if (lat != null && lon != null && !isNaN(lat) && !isNaN(lon)) {
          const customIcon = L.divIcon({
            className: 'custom-div-icon',
            html: `
              <div style="
                background-color: ${dataSource === 'NOAA' ? `rgba(51, 136, 255, ${opacity})` : 'blue'}; 
                width: 30px; 
                height: 30px; 
                border-radius: 50%; 
                display: flex; 
                justify-content: center; 
                align-items: center; 
                color: white; 
                font-weight: bold;
                font-size: 10px;
              ">
                ${magnitude}
              </div>
            `,
            iconSize: [30, 30],
            iconAnchor: [15, 15]
          });
    
          try {
            let popupContent;
            if (dataSource === 'NOAA') {
              popupContent = `
                <b>${event.EVENT_TYPE}</b><br>
                Date: ${new Date(event.BEGIN_DATE_TIME).toLocaleString()}<br>
                Size: ${magnitude} ${magnitudeType}<br>
                Comment: ${dataSource === 'NOAA' ? event.EVENT_NARRATIVE : event.Comments || 'N/A'}<br>
                ${dataSource === 'NOAA' ? `Source: ${event.SOURCE || 'N/A'}<br>` : ''}
              `;
            } else {
              const date = new Date(event.BEGIN_DATE_TIME);
              popupContent = `
                <b>Hail Sighting</b><br>
                Size: ${event.SizeInInches} inches<br>
                Date: ${date.toLocaleDateString()}<br>
                Time: ${date.toLocaleTimeString()}<br>
                Location: ${event.Location || 'N/A'}<br>
                Comment: ${event.Comments || 'N/A'}
              `;
            }

            L.marker([lat, lon], { icon: customIcon })
              .addTo(mapInstanceRef.current)
              .bindPopup(popupContent);
          } catch (error) {
            console.error('Error adding marker:', error, event);
          }
        }
      });
    }
  }, [events, dataSource, coordinates, radius]);

  // Helper function to determine zoom level based on radius
  const getZoomLevel = (radius) => {
    const radiusInMiles = radius / 1609.34; // Convert meters to miles
    if (radiusInMiles <= 1) return 14;
    if (radiusInMiles <= 2) return 13;
    if (radiusInMiles <= 5) return 12;
    if (radiusInMiles <= 10) return 11;
    return 10; // Default zoom level for larger radii
  };

  return <div class="map-container leaflet-container" ref={mapRef}></div>;
}

export default EventsMap;