import React, { useState, useEffect } from 'react';
import SearchForm from './SearchForm';
import EventsMap from './EventsMap';
import EventsTable from './EventsTable';
import PasswordForm from '../Authentication/PasswordForm';
import useStormEvents from '../../hooks/useStormEvents';
import { createClient } from '@supabase/supabase-js';
import { supabaseUrl, supabaseKey } from '../../constants/apiConstants';

const supabase = createClient(supabaseUrl, supabaseKey);

function StormEvents() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const {
    address,
    setAddress,
    coordinates,
    events,
    totalCount,
    loading,
    error,
    dataSource,
    setDataSource,
    handleSearch,
    radius,
    setRadius,
    yearsBack,
    setYearsBack,
  } = useStormEvents(supabase);

  useEffect(() => {
    const lastAuthTime = localStorage.getItem('lastAuthTime');
    if (lastAuthTime && Date.now() - parseInt(lastAuthTime) < 3600000) {
      setIsAuthenticated(true);
    }
  }, []);

  if (!isAuthenticated) {
    return <PasswordForm onAuthenticate={() => setIsAuthenticated(true)} />;
  }

  return (
    <div>
      <h2>Hail Spottings Search</h2>
      <SearchForm
        address={address}
        setAddress={setAddress}
        radius={radius}
        setRadius={setRadius}
        yearsBack={yearsBack}
        setYearsBack={setYearsBack}
        dataSource={dataSource}
        setDataSource={setDataSource}
        onSearch={handleSearch}
        loading={loading}
      />
      <EventsMap
        coordinates={coordinates}
        events={events}
        dataSource={dataSource}
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {coordinates && (
        <p>
          Search Location: Latitude: {coordinates.lat.toFixed(4)}, Longitude: {coordinates.lng.toFixed(4)}
        </p>
      )}
      <EventsTable
        events={events}
        totalCount={totalCount}
        coordinates={coordinates}
        dataSource={dataSource}
      />
    </div>
  );
}

export default StormEvents;