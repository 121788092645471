import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { supabaseUrl, supabaseKey } from '../../constants/apiConstants';
import './ClientManager.css'; // Assuming you have a CSS file for styling

const supabase = createClient(supabaseUrl, supabaseKey);

function ClientManager() {
  const [clients, setClients] = useState([]);
  const [assets, setAssets] = useState([]);
  const [newClient, setNewClient] = useState({ name: '', email: '', phone: '' });
  const [newAsset, setNewAsset] = useState({ client_id: '', address: '', report_url: '' });
  const [selectedClient, setSelectedClient] = useState(null);
  const [showAddClientForm, setShowAddClientForm] = useState(false);
  const [showAddAssetForm, setShowAddAssetForm] = useState(false);

  useEffect(() => {
    console.log('Fetching clients and assets');
    fetchClients();
    fetchAssets();
  }, []);

  const fetchClients = async () => {
    const { data, error } = await supabase.from('clients').select('*');
    if (error) {
      console.error('Error fetching clients:', error);
    } else {
      console.log('Fetched clients:', data);
      setClients(data || []);
    }
  };

  const fetchAssets = async () => {
    const { data, error } = await supabase.from('assets').select('*');
    if (error) {
      console.error('Error fetching assets:', error);
    } else {
      console.log('Fetched assets:', data);
      setAssets(data || []);
    }
  };

  const addClient = async () => {
    const { data, error } = await supabase.from('clients').insert([newClient]);
    if (error) {
      console.error('Error adding client:', error);
    } else {
      console.log('Added client:', data);
      setClients([...clients, ...(data || [])]);
      setNewClient({ name: '', email: '', phone: '' });
      setShowAddClientForm(false);
    }
  };

  const addAsset = async () => {
    const { data, error } = await supabase.from('assets').insert([newAsset]);
    if (error) {
      console.error('Error adding asset:', error);
    } else {
      console.log('Added asset:', data);
      setAssets([...assets, ...(data || [])]);
      setNewAsset({ client_id: '', address: '', report_url: '' });
      setShowAddAssetForm(false);
    }
  };

  const handleClientSelect = (clientId) => {
    console.log('Selected client:', clientId);
    setSelectedClient(clientId);
  };

  const filteredAssets = selectedClient
    ? assets.filter((asset) => asset.client_id === selectedClient)
    : assets;

  return (
    <div className="client-manager">
      <h2>Client Manager</h2>
      
      <section className="add-client">
        <h3>
          <button onClick={() => setShowAddClientForm(!showAddClientForm)}>
            {showAddClientForm ? 'Cancel' : 'Add New Client +'}
          </button>
        </h3>
        {showAddClientForm && (
          <div>
            <input
              type="text"
              placeholder="Name"
              value={newClient.name}
              onChange={(e) => setNewClient({ ...newClient, name: e.target.value })}
            />
            <input
              type="email"
              placeholder="Email"
              value={newClient.email}
              onChange={(e) => setNewClient({ ...newClient, email: e.target.value })}
            />
            <input
              type="text"
              placeholder="Phone"
              value={newClient.phone}
              onChange={(e) => setNewClient({ ...newClient, phone: e.target.value })}
            />
            <button onClick={addClient}>Add Client</button>
          </div>
        )}
      </section>

      <section className="select-client">
        <h3>Select Client</h3>
        <select
          value={selectedClient || ''}
          onChange={(e) => handleClientSelect(e.target.value)}
        >
          <option value="">Select Client</option>
          {clients.map((client) => (
            <option key={client.id} value={client.id}>
              {client.name}
            </option>
          ))}
        </select>
      </section>

      <section className="add-asset">
        <h3>
          <button onClick={() => setShowAddAssetForm(!showAddAssetForm)}>
            {showAddAssetForm ? 'Cancel' : 'Add New Asset +'}
          </button>
        </h3>
        {showAddAssetForm && (
          <div>
            <select
              value={newAsset.client_id}
              onChange={(e) => setNewAsset({ ...newAsset, client_id: e.target.value })}
            >
              <option value="">Select Client</option>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
            <input
              type="text"
              placeholder="Address"
              value={newAsset.address}
              onChange={(e) => setNewAsset({ ...newAsset, address: e.target.value })}
            />
            <input
              type="text"
              placeholder="Report URL"
              value={newAsset.report_url}
              onChange={(e) => setNewAsset({ ...newAsset, report_url: e.target.value })}
            />
            <button onClick={addAsset}>Add Asset</button>
          </div>
        )}
      </section>

      <section className="client-list">
        <h3>Clients</h3>
        <ul>
          {clients.map((client) => (
            <li key={client.id} onClick={() => handleClientSelect(client.id)}>
              {client.name} - {client.email} - {client.phone}
            </li>
          ))}
        </ul>
      </section>

      <section className="asset-list">
        <h3>Assets</h3>
        <ul>
          {filteredAssets.map((asset) => (
            <li key={asset.id}>
              {asset.address} - {asset.report_url}
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}

export default ClientManager;