import React, { useState, useCallback, useEffect } from 'react';
import useGeocoding from '../../hooks/useGeocoding';
import { debounce } from 'lodash';

const radiusOptions = [
  { label: '1 mile', value: 1609.34 },
  { label: '2 miles', value: 3218.69 },
  { label: '5 miles', value: 8046.72 },
  { label: '10 miles', value: 16093.4 },
  { label: '20 miles', value: 32186.9 }
];

const yearsOptions = [
  { label: '1 year', value: 1 },
  { label: '2 years', value: 2 },
  { label: '5 years', value: 5 },
  { label: '10 years', value: 10 },
  { label: '15 years', value: 15 },
  { label: '20 years', value: 20 }
];

function SearchForm({
  address,
  setAddress,
  radius,
  setRadius,
  yearsBack,
  setYearsBack,
  dataSource,
  setDataSource,
  onSearch,
  loading
}) {
  const [autocompleteEnabled, setAutocompleteEnabled] = useState(false);
  const { getAddressSuggestions } = useGeocoding();
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);

   // Set default values
   useEffect(() => {
    if (!radius) setRadius(3218.69); // Default to 2 miles note
    if (!yearsBack) setYearsBack(5); // Default to 5 years
    if (!dataSource) setDataSource('NOAA'); // Default to NOAA
  }, [radius, yearsBack, dataSource, setRadius, setYearsBack, setDataSource]);

  const debouncedGetSuggestions = useCallback(
    debounce(async (input) => {
      if (autocompleteEnabled && input.length > 2) {
        const suggestions = await getAddressSuggestions(input);
        setAddressSuggestions(suggestions);
      } else {
        setAddressSuggestions([]);
      }
    }, 300),
    [autocompleteEnabled, getAddressSuggestions]
  );

  const handleAddressChange = (e) => {
    const input = e.target.value;
    setAddress(input);
    debouncedGetSuggestions(input);
  };

  const handleSuggestionClick = (suggestion) => {
    setAddress(suggestion);
    setAddressSuggestions([]);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setActiveIndex(prev => (prev < addressSuggestions.length - 1 ? prev + 1 : prev));
    } else if (e.key === 'ArrowUp') {
      setActiveIndex(prev => (prev > 0 ? prev - 1 : prev));
    } else if (e.key === 'Enter' && activeIndex !== -1) {
      handleSuggestionClick(addressSuggestions[activeIndex]);
    }
  };

  return (
    <div className="search-form">
      <div className="form-group">
        <label htmlFor="radius">Search Radius:</label>
        <select
          id="radius"
          value={radius}
          onChange={(e) => setRadius(Number(e.target.value))}
          className="form-control"
        >
          {radiusOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="yearsBack">Years ago:</label>
        <select
          id="yearsBack"
          value={yearsBack}
          onChange={(e) => setYearsBack(Number(e.target.value))}
          className="form-control"
        >
          {yearsOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>Data Source:</label>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              value="NOAA"
              checked={dataSource === 'NOAA'}
              onChange={() => setDataSource('NOAA')}
            />
            NOAA Hail Events Archive
          </label>
          <label>
            <input
              type="radio"
              value="Unfiltered"
              checked={dataSource === 'Unfiltered'}
              onChange={() => setDataSource('Unfiltered')}
            />
            Unfiltered Sightings
          </label>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="address">Address:</label>
        <div className="autocomplete-wrapper">
          <input
            id="address"
            type="text"
            value={address}
            onChange={handleAddressChange}
            onKeyDown={handleKeyDown}
            placeholder="Enter address"
            className="form-control"
            aria-autocomplete="list"
            aria-controls="address-suggestions"
          />
          {autocompleteEnabled && addressSuggestions.length > 0 && (
            <ul id="address-suggestions" className="suggestions-list">
              {addressSuggestions.map((suggestion, index) => (
                <li 
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className={index === activeIndex ? 'active' : ''}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="checkbox-group">
          <input
            id="autocomplete"
            type="checkbox"
            checked={autocompleteEnabled}
            onChange={() => setAutocompleteEnabled(!autocompleteEnabled)}
          />
          <label htmlFor="autocomplete">Enable Autocomplete</label>
        </div>
      </div>

      <button 
        onClick={onSearch} 
        disabled={loading || !address}
        className="search-button"
      >
        {loading ? 'Searching...' : 'Search'}
      </button>
    </div>
  );
}

export default SearchForm;